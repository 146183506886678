<template>
  <div class="tournament">
    <el-row style="padding-top: 3vh" type="flex" justify="center">
      <el-col :lg="12" :xs="24" :sm="24" :md="24">
        <el-card
            :body-style="{
              padding: '0px',
              background: '#1e1e1e',

            }"
            shadow="never"
            class="card"
            >
          <div class="card-body">
            <div class="card-body-title">
              <span>Malody Jack House Cup 2024</span>
            </div>
            <div class="card-body-content">
              <el-link href="https://m.mugzone.net/wiki/2381?lang=sc" :underline="false" icon="el-icon-link">{{ $t('tournament.mainWiki') }}</el-link>
              <el-link href="https://m.mugzone.net/wiki/2374?lang=sc" :underline="false" icon="el-icon-link">{{ $t('tournament.quaWiki') }}</el-link>
              <el-link href="https://docs.qq.com/sheet/DVmVLcWFvWWpwQkZW" :underline="false" icon="el-icon-document">{{ $t('tournament.masterSheet') }}</el-link>
              <el-link href="https://challonge.com/MJHC2024" :underline="false" icon="el-icon-date">{{ $t('tournament.challonge') }}</el-link>
              <el-link href="https://qm.qq.com/q/S85FrN0ruy" :underline="false" icon="el-icon-chat-dot-square">{{ $t('tournament.QQ') }}</el-link>
              <el-link href="https://discord.com/invite/uQhBXZ2Y7s" :underline="false" icon="el-icon-chat-dot-round">{{ $t('tournament.Discord') }}</el-link>
            </div>
          </div>
        </el-card>
        <el-card
            :body-style="{
              padding: '0px',
              background: '#1e1e1e',

            }"
            shadow="never"
            class="card"
        >
          <div class="card-body">
            <div class="card-body-title">
              <span>Jack House Cup 2024</span>
            </div>
            <div class="card-body-content">
              <el-link href="https://osu.ppy.sh/wiki/zh/Tournaments/JHC/JHC_2024" :underline="false" icon="el-icon-link">{{ $t('tournament.mainWiki') }}</el-link>
              <el-link href="https://osu.ppy.sh/community/forums/topics/1827578" :underline="false" icon="el-icon-link">{{ $t('tournament.forum') }}</el-link>
              <el-link href="https://docs.google.com/spreadsheets/d/1Qm2BfIc2LFbf59-2RBkqnOde95y2WgoyO_XRNSrvNa4" :underline="false" icon="el-icon-document">{{ $t('tournament.masterSheet') }}</el-link>
              <el-link href="https://challonge.com/ehi7s3y0" :underline="false" icon="el-icon-date">{{ $t('tournament.challonge') }}</el-link>
              <el-link href="http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=EaeZzi_S3U2NPBketQ4sPtTpE0dcL1Qo&authKey=%2B4J9ptETQxVzGzBdLd1pFcQqXRjDpKHp5AX1rAdtXfyZNTC0TSqrkT5kCO01wDyk&noverify=0&group_code=716822817" :underline="false" icon="el-icon-chat-dot-square">{{ $t('tournament.QQ') }}</el-link>
              <el-link href="https://discord.gg/DqPuRU8Bhc" :underline="false" icon="el-icon-chat-dot-round">{{ $t('tournament.Discord') }}</el-link>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.el-card{
  border: none;
  margin-bottom: 3vh;
}

.card{
  position: relative;
  width: 100%;
  height: auto;
  display: block;
  box-sizing: border-box;
  background-color: #1e1e1e;
  &::before,
  &::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    box-sizing: border-box;
    border: 1px solid #e1e1e1;
    transition: clip-path .5s ease-in-out;
  }
  &::before{
    clip-path: inset(0 75% 60% 0);
  }
  &::after{
    clip-path: inset(60% 0 0 75%);
  }
  &:hover{
    &::before,
    &::after{
      clip-path: inset(0 0 0 0);
    }
  }
}

.card-body{
  padding-left: 15px;
}

.card-body-title{
  font-size: 18px;
  color: #aeadad;
  font-weight: bold;
  padding-top: 1.5vh;
  padding-bottom: 0.5vh;
}

.card-body-content{
  padding-bottom: 2vh;
}

.el-link{
  width: 50%;
  justify-content: flex-start;
  line-height: normal;
  z-index: 1000;
  &:hover{
     color: #aeadad;
  }
}

.card-image{
  vertical-align: middle;
  text-align: center
  /*width: 100%;
  background-image: url(../assets/carousel_1.jpg);
  background-size: cover;
  background-position: center;*/
}

img{
  width: 100%;
}

</style>
