<template>
  <div>
    <el-select @change="langChange" v-model="recentLang" :popper-append-to-body="false" class="select-box">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        <span style="float: left">
          <img :src="item.icon" alt="" height="20px" style="padding-top: 7px">
        </span>
        <span style="float: left; padding-left: 5px">{{ item.label }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'lang',
  data(){
    return{
      recentLang: this.$store.state.langs,
      options:[{
        value: 'zh',
        label: '简体中文',
        icon: 'https://cdn.jsdelivr.net/npm/@twemoji/svg@latest/1f1e8-1f1f3.svg'
      },{
        value: 'en',
        label: 'English',
        icon: 'https://cdn.jsdelivr.net/npm/@twemoji/svg@latest/1f1ec-1f1e7.svg'
      }]
    }
  },
  methods: {
    langChange(val){
      this.$nextTick(()=>{
        this.$store.commit('setLangInfo',val);
        localStorage.setItem('lang',val);
        this.$i18n.locale = val;
      })
    }
  }
}
</script>

<style scoped>
.select-box{
  width: 120px;
  height: 40px;
  margin: 8px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 1), 0 10px 20px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

:deep .el-input__inner{
  background-color: #1e1e1e;
  color: #e1e1e1;
  border: 0;
  font-size: 14px;
  border-radius: 10px;
  font-family: Blueaka;
}

:deep .el-input__inner:focus,
:deep .el-input__inner:hover{
  border: 0;
  background-color: #171717;
  transition: 0.2s ease-in-out;
}

:deep .el-select-dropdown__list{
  background-color: #1e1e1e;
  margin: 0;
}

:deep .el-popper{
  border: 0;
}

:deep .el-popper .popper__arrow{
  border-bottom-color: transparent !important;
  border-style: none !important;

}

:deep .el-popper .popper__arrow::after{
  border-bottom-color: transparent !important;
  border-style: none !important;
}

:deep .el-select-dropdown__item{
  background-color: #1e1e1e;
  padding: 0 10px;
}

:deep .el-select-dropdown__item:hover,
:deep .el-select-dropdown__item.hover{
  background-color: #171717 !important;
}

:deep .el-select-dropdown__item.selected{
  color: #e1e1e1;
  font-weight: normal;
}

</style>