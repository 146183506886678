export default {
    menu:{
        home: 'home',
        search: 'jack packs',
        tournament: 'tournaments',
        about: 'about',
    },
    home:{
        activities: 'Recent Activities',
    },
    tournament:{
        mainWiki: 'Main WIKI',
        quaWiki: 'Qualifiers WIKI',
        forum: 'Forum Page',
        masterSheet: 'Master Sheet',
        challonge: 'Challonge',
        QQ: 'QQ Group',
        Discord: 'Discord Server',
    },
    about:{
        jackHouse: '4Key Jack House',
        slogan: "the world's largest 4k jack player community",
        p1: '4Key Jack House, founded on June 1, 2022, is essentially a jack maps repository and a community for jack players. As of September 2024, it has over 2,000 members from all over the world, with the majority being Chinese players. Here, you can get various jack practice packs, participate in rare only-jack tournaments in 4Key community, and interact with jack players from around the globe! There are no entrance requirements or examinations — whether jack main or not, we welcome you to join us!',
        thanks: 'Thanks to kivo.fun for Blue Archive fonts',
    },
    search: {
        search: 'Total',
        search2: '',
        placeholder: 'Please enter keywords (title or creator)',
        title: 'Title',
        link: 'Link',
        emptyText: 'No results found',
        creator: 'Creator',
        BPM: 'BPM range',
        DF: 'Diff. range',
    },
    develop: {
        developing: 'coming soon...',
    }
}