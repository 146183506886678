import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import i18n from './lang/index'
import '@/assets/fonts/font.css'
import '@/assets/fonts/Blueaka/Blueaka.css'
import '@/assets/fonts/Blueaka_Bold/Blueaka_Bold.css'
import { Messages } from './utils/reMessage'

//element-ui
import elementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(elementUI);

Vue.config.productionTip = false
Vue.prototype.$message = Messages;

new Vue({
  render: h => h(App),
  router,
  i18n,
  store
}).$mount('#app')
