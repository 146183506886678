import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        langs: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
    },
    getters: {
    },
    mutations: {
        setLangInfo(state, val) {
            state.langs = val
        },
    },
    actions: {
    },
    modules: {
    },
})