<template>
  <div class="search">
    <div class="title">
<!--      <h4 align="center" style="color: #aeadad; margin-top: 0; font-style: italic; padding-top: 2vh; margin-bottom: 0;">
        {{ $t("search.search") }}  '{{ this.jackMaps.length }}'  {{ $t("search.search2") }}
      </h4>-->
    </div>
    <el-row style="padding-top: 3vh" type="flex" justify="center">
      <el-col :lg="12" :xs="20" :sm="20" :md="12">
        <el-input
            v-model="keywords"
            :placeholder="placeholder"
            prefix-icon="el-icon-search"
            clearable
            @input="debouncedGetResult"
            class="search-input"
        ></el-input>
      </el-col>
    </el-row>

<!--    电脑的筛选部分-->
    <el-row style="padding-top: 2vh" type="flex" justify="center" v-if="!isMobile">
      <el-col :lg="6" :xs="10" :sm="10" :md="6">
        <div style="color: #aeadad;" align="center" >
          <span style="padding-right: 6px; font-size: 14px">{{ $t("search.BPM") }} :</span>
          <el-input
              style="width: 4em; margin-right: 6px;"
              v-model="minBpm"
              maxlength="3"
              placeholder="MIN"
              class="bpm-search"
              onfocus="this.placeholder=''"
              onblur="this.placeholder='MIN'"
              @input="debouncedGetResult"></el-input>
          <span style="padding-right: 4px">~</span>
          <el-input
              style="width: 4em"
              v-model="maxBpm"
              maxlength="3"
              placeholder="MAX"
              class="bpm-search"
              onfocus="this.placeholder=''"
              onblur="this.placeholder='MAX'"
              @input="debouncedGetResult"></el-input>
        </div>
      </el-col>
      <el-col :lg="6" :xs="10" :sm="10" :md="6">
        <div style="color: #aeadad" align="center">
          <span style="padding-right: 6px; font-size: 14px">{{ $t("search.DF") }} : {{ $t("develop.developing") }}</span>
        </div>
      </el-col>
    </el-row>

<!--    手机的筛选部分-->
    <el-row style="padding-top: 2vh" type="flex" justify="center" v-if="isMobile">
      <el-col :lg="12" :xs="20" :sm="20" :md="12">
        <div style="color: #aeadad;" align="center" >
          <span style="padding-right: 6px; font-size: 14px">{{ $t("search.BPM") }} :</span>
          <el-input
              style="width: 4em; margin-right: 6px;"
              v-model="minBpm"
              maxlength="3"
              placeholder="MIN"
              class="bpm-search"
              onfocus="this.placeholder=''"
              onblur="this.placeholder='MIN'"
              @input="debouncedGetResult"></el-input>
          <span style="padding-right: 4px">~</span>
          <el-input
              style="width: 4em"
              v-model="maxBpm"
              maxlength="3"
              placeholder="MAX"
              class="bpm-search"
              onfocus="this.placeholder=''"
              onblur="this.placeholder='MAX'"
              @input="debouncedGetResult"></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row style="padding-top: 0" type="flex" justify="center" v-if="isMobile">
      <el-col :lg="12" :xs="20" :sm="20" :md="12">
        <div style="color: #aeadad" align="center">
          <span style="padding-right: 6px; font-size: 14px">{{ $t("search.DF") }} : {{ $t("develop.developing") }}</span>
        </div>
      </el-col>
    </el-row>

    <el-row style="padding: 2vh 0 5vh 0" type="flex" justify="center">
      <el-col :lg="14" :xs="22" :sm="22" :md="14">
        <el-table :data="resultMaps.slice((currentPage - 1) * pageSize, currentPage * pageSize)" :row-style="searchTable" :header-cell-style=searchTableHead :cell-style="searchTable" :empty-text="emptyText">
          <el-table-column :label="title" align="center" prop="title" min-width="250px">
            <template slot-scope="scope">
              <el-tooltip placement="right"
                          effect="dark"
                          visible-arrow>
                <div slot="content">
                  <span style="color: #aeadad" v-if="scope.row.tipsExist">{{ scope.row.tips }}</span>
                  <span style="color: #aeadad" v-if="!scope.row.tipsExist">BPM: {{ scope.row.minBPM }} ~ {{ scope.row.maxBPM }}</span>
                </div>
                <span style="margin: 0">{{ scope.row.title }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column :label="creator" align="center" prop="creator" v-if="!isMobile"/>
          <el-table-column :label="link" align="center" prop="link">
            <template slot-scope="scope">
              <div class="icon">
                <img src="@/assets/osu-logo-white.svg" width="26" height="26" @click="osuLink(scope.row.osuID)" class="osu" v-show="scope.row.linkExist" />
                <img src="@/assets/google.svg" width="21" height="21" @click="openLink(scope.row.driveLink)" class="google" v-show="scope.row.driveLinkExist" />
                <img src="@/assets/cloud-download.svg" width="21" height="21" @click="openLink(scope.row.lanzou)" class="google" v-show="scope.row.lanzouExist" />
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :total="resultMaps.length"
                       :current-page="currentPage"
                       :page-size="pageSize"
                       style="text-align: center; margin-top: 2vh"
                       @current-change="currentPageChange"
                       layout="prev, pager, next, ->, slot"
                       background
                       :pager-count="4"
                       >
          <span style="color: #686868">{{ $t("search.search") }}  {{ this.resultMaps.length }}  {{ $t("search.search2") }}</span>
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import packs from '@/assets/packs.json'
export default {
  data() {
    return {
      jackMaps: [],
      resultMaps: [],
      keywords: '', //输入的搜索内容
      minBpm: '',
      maxBpm: '',
      isMobile: false,
      timer: null, //防抖计时器

      //语言更新设置
      placeholder: this.$t('search.placeholder'),
      title: this.$t('search.title'),
      link: this.$t('search.link'),
      emptyText: this.$t('search.emptyText'),
      creator: this.$t('search.creator'),

      //分页设置
      currentPage: 1,
      pageSize: 9,

      //样式设置
      searchTable: {
        "background-color": "#1e1e1e",
        "border": "none",
        "color": "#aeadad",
        "padding": "8px 0",
      },
      searchTableHead: {
        "background-color": "#1a1a1a",
        "color": "#aeadad",
        "font-size": "16px",
        "border-bottom": "none",
        "padding": "6px 0",
      }
    };
  },
  methods: {
    placeholderChange(){
      this.placeholder = this.$t('search.placeholder');
      this.title = this.$t('search.title');
      this.link = this.$t('search.link');
      this.emptyText = this.$t('search.emptyText');
      this.creator = this.$t('search.creator');
    },
    osuLink(id){
      let link = "https://osu.ppy.sh/beatmapsets/" + id;
      this.openLink(link);
    },
    openLink(link){
      window.open(link,'_blank');
    },
    getResult(){
      this.resultMaps = this.jackMaps.filter(item =>
      {
        const itemMinBPM = parseFloat(item.minBPM);
        const itemMaxBPM = parseFloat(item.maxBPM);

        const minBPMValid = this.minBpm === '' || !isNaN(parseFloat(this.minBpm));
        console.log(this.minBpm === '');
        const maxBPMValid = this.maxBpm === '' || !isNaN(parseFloat(this.maxBpm));
        const BPMValid = (this.minBpm === '' || this.maxBpm === '' || parseFloat(this.minBpm) < parseFloat(this.maxBpm));

        if( !minBPMValid || !maxBPMValid || !BPMValid ){
          if( this.$store.state.langs === 'en'){
            this.$message({
              message: 'Please enter a valid BPM range.',
              type: 'warning'
            });
          }
          else{
            this.$message({
              message: '请输入正确的BPM范围',
              type: 'warning'
            });
          }
          return;
        }

        const matchesKeyword = item.title.toLowerCase().includes(this.keywords.toLowerCase()) ||
            (item.creator && item.creator.toLowerCase().includes(this.keywords.toLowerCase()));

        const matchesBpm = (this.minBpm === '' || itemMinBPM >= parseFloat(this.minBpm)) &&
            (this.maxBpm === '' || itemMaxBPM <= parseFloat(this.maxBpm));

        return matchesKeyword && matchesBpm;
      })
    },
    debouncedGetResult(){ //搜索防抖
      clearTimeout(this.timer);
      this.timer = setTimeout(()=>{
        this.getResult();
      },500)
    },
    checkScreenSize(){
      this.isMobile = window.innerWidth <= 768;
      if(this.isMobile){
        console.log(this.isMobile);
      }
    },
    currentPageChange(currentPage){
      this.currentPage = currentPage;
    },
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
    this.jackMaps = packs;
    this.jackMaps.forEach(map => {
      map.linkExist = (map.osuID.length !== 0);
      map.driveLinkExist = (map.driveLink && map.driveLink.length !== 0);
      map.lanzouExist = (map.lanzou && map.lanzou.length !== 0);
      map.tipsExist = (map.tips.length !== 0);
    })
    this.resultMaps = this.jackMaps;
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.checkScreenSize);
  },
  watch:{
    '$i18n.locale'(){ //监听语言变化以改变placeholder语言
      this.placeholderChange();
    }
  }
};
</script>

<style scoped>
/deep/ .search-input .el-input__inner {
  background-color: #1e1e1e;
  border: 0;
  height: 6vh;
  font-family: Blueaka;
  color: #aeadad;
  border-radius: 15px;
  padding-left: 36px !important;
}

/deep/ .bpm-search .el-input__inner {
  background-color: #1e1e1e;
  border: 0;
  height: 5vh;
  font-family: Blueaka;
  font-size: 12px;
  color: #aeadad;
  border-radius: 15px;
  padding: 0 10px;
  text-align: center;
}

/deep/ .el-input__suffix{
  padding-right: 5px;
}

/deep/ input::-webkit-input-placeholder{
  -webkit-text-fill-color: #aeadad;
}

/deep/ .el-table tbody tr{
  background-color: #1e1e1e;
  border: 0 !important;
}

/deep/ .el-table tbody tr:hover > td {
  background-color: #1a1a1a !important;
  color: #c6c3c3 !important;
}

.el-table{
  &:before {
    height: 0;
  }
}

.icon{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.osu{
  &:hover{
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
  }
}
.google{
  padding: 2.5px 0;
  &:hover{
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
  }
}

/deep/ .el-table__empty-block{
  background-color: #1e1e1e;
}

/* 修改前后箭头未点击时的背景颜色 */
:deep .el-pagination .btn-prev,
:deep .el-pagination .btn-next {
  background-color: #1e1e1e !important;
  color: #e1e1e1 !important;
}

:deep .el-pagination .btn-prev:disabled,
:deep .el-pagination .btn-next:disabled {
  color: #8a8888 !important;
}

:deep .el-pagination .el-pager li:not(.active):not(.disabled):hover {
  background-color: #1e1e1e !important;
  color: #e1e1e1;
}

/* 未点击时的数字方块背景颜色 */
:deep .el-pagination .el-pager li:not(.active):not(.disabled) {
  background-color: #1e1e1e !important;
  color: #aeadad;
}


:deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
  background-color: #171717 !important;
  color: #aeadad;
}

</style>