<template>
  <div id="app">
      <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #171717; /* 你想要的任何颜色 */
}
body {
  font-family: 'Blueaka' , 'Manrope';
}
</style>