import Vue from 'vue'
import VueI18n from 'vue-i18n'
// 从语言包文件中导入语言包对象
import zh from './zh.js'
import en from './en.js'
//使用vuex
import store from '../store'
// element-ui 组件国际化
import ElementLocale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

ElementLocale.i18n((key, value) => i18n.t(key, value))

Vue.use(VueI18n)
const messages = {
    zh: {
        ...zh,
        ...zhLocale
    },
    en: {
        ...en,
        ...enLocale
    },
}
console.log(store.state.langs)

const i18n = new VueI18n({
    messages,
    locale: store.state.langs, //初始默认中文
    fallbackLocale: 'en',
    silentFallbackWarn: true, // 抑制警告
    globalInjection: true, // 全局注入
    silentTranslationWarn: true
})

export default i18n