<template>
  <div class="home">
    <el-row style="padding-top: 1vh" type="flex" justify="center">
      <el-col :lg="18" :xs="24" :sm="24" :md="24">
        <el-carousel height="60vh">
          <el-carousel-item v-for="item in carouselImg" :key="item.id">
            <img :src="item.url" alt="" style="object-fit: scale-down; width: 100%; height: 100%">
          </el-carousel-item>
        </el-carousel>
      </el-col>
    </el-row>
    <el-row style="padding-top: 2vh" type="flex" justify="center">
      <el-col :lg="18" :xs="24" :sm="24" :md="24">
        <p class="title">{{ $t('home.activities') }}</p>
        <div class="activity">
          <div class="bili">
            <p class="videoTitle">MJHC Finals Mappool Showcase</p>
            <iframe src="//player.bilibili.com/player.html?bvid=BV1xKHCe5EqM&autoplay=0" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true" width="100%" style="padding-bottom: 2vh"></iframe>
          </div>
          <div class="bili">
            <p class="videoTitle">MJHC 2024 Semifinals: lsjx vs. laser</p>
            <iframe src="//player.bilibili.com/player.html?bvid=BV1sTHCeCE95&autoplay=0" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true" style="padding-bottom: 2vh"></iframe>
          </div>
          <div class="bili">
            <p class="videoTitle">MJHC Semifinals Mappool Showcase</p>
            <iframe src="//player.bilibili.com/player.html?bvid=BV12vsWeeEmH&autoplay=0" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true" style="padding-bottom: 2vh"></iframe>
          </div>
          <div class="bili">
            <p class="videoTitle">MJHC Quarterfinals: lsjx vs. xhhy</p>
            <iframe src="//player.bilibili.com/player.html?bvid=BV1FuW6eYEbW&autoplay=0" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true" width="100%" style="padding-bottom: 2vh"></iframe>
          </div>
          <div class="bili">
            <p class="videoTitle">MJHC Quarterfinals: anfish vs qinhaibao</p>
            <iframe src="//player.bilibili.com/player.html?bvid=BV1g1Wqe3Ew9&autoplay=0" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true" style="padding-bottom: 2vh"></iframe>
          </div>
          <div class="bili">
            <p class="videoTitle">MJHC Quarterfinals: CaptainLan vs laser</p>
            <iframe src="//player.bilibili.com/player.html?bvid=BV1J8WzeFEnR&autoplay=0" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true" style="padding-bottom: 2vh"></iframe>
          </div>
          <div class="bili">
            <p class="videoTitle">MJHC Quarterfinals Mappool Showcase</p>
            <iframe src="//player.bilibili.com/player.html?bvid=BV1S1WqegEiV&autoplay=0" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true" style="padding-bottom: 2vh"></iframe>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-backtop style="background-color: #e1e1e1">
        <div
        style="{
          height: 100%;
          width: 100%;
          background-color: #e1e1e1;
          text-align: center;
          border-radius: 50%;
          line-height: 40px;
          color: #1e1e1e;
        }">
          ▲
        </div>
    </el-backtop>
  </div>
</template>

<script>
import url1 from '../assets/carousel_1.jpg'
import url2 from '../assets/carousel_2.png'
import url3 from '../assets/carousel_3.jpg'

export default {
  data(){
    return {
      carouselImg: [
        {
          id: 1,
          url: url1,
        },
        {
          id: 2,
          url: url2,
        },
        {
          id: 3,
          url: url3,
        },
      ],
      previewImg: [url1,url2,url3],
    }
  }
}
</script>

<style scoped>
.title{
  text-align: center;
  font-size: 24px;
  color: #e1e1e1;
}
.subtitle{
  text-align: center;
  font-size: 20px;
  color: #aeadad;
}
.videoTitle{
  font-size: 16px;
  color: #aeadad;
  padding-left: 0.5vw;
}
.el-icon-caret-top{
  color: #1e1e1e !important;
}
.activity{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bili{
  position: relative;
  width: 100%; /* 默认宽度100% */
  height: 56.25%; /* 16:9 的宽高比 */
  overflow: hidden; /* 隐藏溢出的内容 */
  margin-bottom: 2vh;
}

iframe{
  width: 100%; /* 宽度填充父容器 */
  height: 315px;
}

@media (min-width: 768px) {
  .bili {
    max-width: calc(50% - 10px); /* 宽度为容器的一半减去间隔 */
    margin-right: 10px; /* 右边距，为两个视频之间提供间隔 */
  }
}

/* 小型设备，例如手机 */
@media (max-width: 767px) {
  .bili {
    max-width: 100%; /* 在小屏幕上宽度占满整个容器 */
    margin-right: 0; /* 重置右边距 */
  }
}
</style>
