var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tournament"},[_c('el-row',{staticStyle:{"padding-top":"3vh"},attrs:{"type":"flex","justify":"center"}},[_c('el-col',{attrs:{"lg":12,"xs":24,"sm":24,"md":24}},[_c('el-card',{staticClass:"card",attrs:{"body-style":{
            padding: '0px',
            background: '#1e1e1e',

          },"shadow":"never"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-body-title"},[_c('span',[_vm._v("Malody Jack House Cup 2024")])]),_c('div',{staticClass:"card-body-content"},[_c('el-link',{attrs:{"href":"https://m.mugzone.net/wiki/2381?lang=sc","underline":false,"icon":"el-icon-link"}},[_vm._v(_vm._s(_vm.$t('tournament.mainWiki')))]),_c('el-link',{attrs:{"href":"https://m.mugzone.net/wiki/2374?lang=sc","underline":false,"icon":"el-icon-link"}},[_vm._v(_vm._s(_vm.$t('tournament.quaWiki')))]),_c('el-link',{attrs:{"href":"https://docs.qq.com/sheet/DVmVLcWFvWWpwQkZW","underline":false,"icon":"el-icon-document"}},[_vm._v(_vm._s(_vm.$t('tournament.masterSheet')))]),_c('el-link',{attrs:{"href":"https://challonge.com/MJHC2024","underline":false,"icon":"el-icon-date"}},[_vm._v(_vm._s(_vm.$t('tournament.challonge')))]),_c('el-link',{attrs:{"href":"https://qm.qq.com/q/S85FrN0ruy","underline":false,"icon":"el-icon-chat-dot-square"}},[_vm._v(_vm._s(_vm.$t('tournament.QQ')))]),_c('el-link',{attrs:{"href":"https://discord.com/invite/uQhBXZ2Y7s","underline":false,"icon":"el-icon-chat-dot-round"}},[_vm._v(_vm._s(_vm.$t('tournament.Discord')))])],1)])]),_c('el-card',{staticClass:"card",attrs:{"body-style":{
            padding: '0px',
            background: '#1e1e1e',

          },"shadow":"never"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-body-title"},[_c('span',[_vm._v("Jack House Cup 2024")])]),_c('div',{staticClass:"card-body-content"},[_c('el-link',{attrs:{"href":"https://osu.ppy.sh/wiki/zh/Tournaments/JHC/JHC_2024","underline":false,"icon":"el-icon-link"}},[_vm._v(_vm._s(_vm.$t('tournament.mainWiki')))]),_c('el-link',{attrs:{"href":"https://osu.ppy.sh/community/forums/topics/1827578","underline":false,"icon":"el-icon-link"}},[_vm._v(_vm._s(_vm.$t('tournament.forum')))]),_c('el-link',{attrs:{"href":"https://docs.google.com/spreadsheets/d/1Qm2BfIc2LFbf59-2RBkqnOde95y2WgoyO_XRNSrvNa4","underline":false,"icon":"el-icon-document"}},[_vm._v(_vm._s(_vm.$t('tournament.masterSheet')))]),_c('el-link',{attrs:{"href":"https://challonge.com/ehi7s3y0","underline":false,"icon":"el-icon-date"}},[_vm._v(_vm._s(_vm.$t('tournament.challonge')))]),_c('el-link',{attrs:{"href":"http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=EaeZzi_S3U2NPBketQ4sPtTpE0dcL1Qo&authKey=%2B4J9ptETQxVzGzBdLd1pFcQqXRjDpKHp5AX1rAdtXfyZNTC0TSqrkT5kCO01wDyk&noverify=0&group_code=716822817","underline":false,"icon":"el-icon-chat-dot-square"}},[_vm._v(_vm._s(_vm.$t('tournament.QQ')))]),_c('el-link',{attrs:{"href":"https://discord.gg/DqPuRU8Bhc","underline":false,"icon":"el-icon-chat-dot-round"}},[_vm._v(_vm._s(_vm.$t('tournament.Discord')))])],1)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }